import inherits from 'inherits';
import { CpTasks } from '../../resources/bpmn4cp';
import { is } from 'bpmn-js/lib/util/ModelUtil';
import ReplaceMenuProvider from 'bpmn-js/lib/features/popup-menu/ReplaceMenuProvider';
import '@fortawesome/fontawesome-free/css/all.css';
import _ from 'lodash';

export default function CpReplaceMenu(popupMenu, modeling, moddle, bpmnReplace, rules, translate) {
  ReplaceMenuProvider.call(this, popupMenu, modeling, moddle, bpmnReplace, rules, translate, 1100);

  this.getEntries = element => {
    const bpmnEntries = this.getCpEntries(element);
    if (is(element, 'bpmn:Task')) {
      const customEntries = [
        ..._.map(CpTasks, task => ({
          id: `replace-with-cp-${task.name}`,
          label: task.displayName,
          className: `fas fa-${task.icon} normalText`,
          action: () => bpmnReplace.replaceElement(element, { type: `cp:${task.name}` })
        })),
        {
          id: 'replace-with-task',
          label: 'Task',
          className: 'bpmn-icon-task',
          action: () => bpmnReplace.replaceElement(element, { type: 'bpmn:Task' })
        }
      ];
      return customEntries;
    }
    if (is(element, 'bpmn:IntermediateThrowEvent')) {
      const customEntries = [
        {
          id: 'replace-with-milestone',
          label: 'Milestone Intermediate Throw Event',
          className: 'fas fa-map-marker-alt normalText',
          action: () =>
            bpmnReplace.replaceElement(element, { type: 'cp:IntermediateThrowMilestoneEvent' })
        },
        ...bpmnEntries
      ];
      return customEntries;
    }
    return bpmnEntries;
  };
}

inherits(CpReplaceMenu, ReplaceMenuProvider);
CpReplaceMenu.prototype.getCpEntries = ReplaceMenuProvider.prototype.getEntries;

CpReplaceMenu.$inject = ['popupMenu', 'modeling', 'moddle', 'bpmnReplace', 'rules', 'translate'];
