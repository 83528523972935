import React from 'react';
import styled from '@emotion/styled';
import 'bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css';
import BpmnEditor from './Bpmn-components/BpmnEditor';
import BpmnViewer from './Bpmn-components/BpmnViewer';
import { BrowserRouter, Route, Link } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Route
        path="/"
        exact
        children={
          <Wrapper>
            <BpmnEditor />
          </Wrapper>
        }
      />
      <Route path="/viewer/:id" exact component={BpmnViewer} />
    </BrowserRouter>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export default App;
