import entryFactory from 'bpmn-js-properties-panel/lib/factory/EntryFactory';
import _ from 'lodash';

import { is } from 'bpmn-js/lib/util/ModelUtil';
import { EvidenceLevel } from '../../../resources/bpmn4cp';

export default (group, element, customProps) =>
  customProps.forEach(customElement => {
    const isApplicable = _.includes(
      customElement.extends.map(prototype => is(element, prototype)),
      true
    );
    if (isApplicable) {
      customElement.properties.forEach(property => {
        const displayElement = {
          id: property.id || property.name,
          description: property.description || '',
          label: property.displayName || property.name,
          modelProperty: property.name
        };
        switch (property.type) {
          case 'Boolean':
            group.entries.push(entryFactory.checkbox(displayElement));
            break;
          case 'cp:EvidenceIndicator':
            group.entries.push(
              entryFactory.selectBox({
                ...displayElement,
                selectOptions: EvidenceLevel.literalValues[0],
                emptyParameter: ''
              })
            );
            break;
          default:
            group.entries.push(entryFactory.textField(displayElement));
        }
      });
    }
  });
