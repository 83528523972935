import React, { useEffect, useState, useRef } from 'react';
import BpmnModeler from 'bpmn-js/lib/Modeler';
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-font/dist/css/bpmn-embedded.css';
import starterBpmn from '../resources/starterBpmn';
import styled from '@emotion/styled';
import _ from 'lodash';
import configuratorExtension from '../resources/cbpmn';
import clinicalPathwaysExtension from '../resources/bpmn4cp';
import modules from '../Bpmn-modules/modules';
import TemplateImporter from './modals/TemplateImporter';
import OwnTemplateManager from './modals/OwnTemplateManager';
import Configurator from './modals/Configurator';
import ConnectionRuleDeclarator from './modals/ConnectionRuleDeclarator';
import { Button } from 'semantic-ui-react';
import { is } from 'bpmn-js/lib/util/ModelUtil';
import { saveAs } from 'file-saver';

function BpmnEditor() {
  const [modeler, setModeler] = useState(null);
  const fileInputRef = useRef();

  const [templateModalIsOpen, setTemplateModalOpenness] = useState(false);
  const [ownRepositoryModalIsOpen, setOwnRepositoryModalOpenness] = useState(false);
  const [configuratorModalIsOpen, setConfiguratorModalOpenness] = useState(false);
  const [connectionRuleDeclaratorModalProps, setConnectionRuleDeclaratorModalProps] = useState({
    isOpen: false,
    activeElementId: undefined
  });
  const [objectivesAreHidden, setObjectivesAreHidden] = useState();

  const [importedXml, setImportedXml] = useState(starterBpmn);

  useEffect(() => {
    document.getElementById('property-panel').innerHTML = '';
    document.getElementById('modeler').innerHTML = '';

    const modeler = new BpmnModeler({
      container: '#modeler',
      keyboard: {
        bindTo: window
      },
      propertiesPanel: {
        parent: '#property-panel'
      },
      cli: {
        bindTo: 'cli'
      },
      additionalModules: modules,
      moddleExtensions: { cp: clinicalPathwaysExtension, cbpmn: configuratorExtension }
    });
    modeler.importXML(importedXml, error => {
      if (error) {
        alert('Failed to create BPMN Diagram.');
        return null;
      }
      modeler.get('canvas').zoom('fit-viewport');
      setModeler(modeler);
      modeler.get('eventBus').fire('elements.changed', {
        elements: _.map(modeler.get('elementRegistry')._elements, object => object.element)
      });
    });

    modeler.on('element.click', element => {
      const source = _.get(element, 'element.source');
      if (_.get(source, 'businessObject.configurable') && is(source, 'bpmn:Gateway')) {
        setConnectionRuleDeclaratorModalProps({
          isOpen: true,
          activeElementId: element.element.id
        });
      }
    });

    modeler.on('commandStack.changed', () => {
      setModeler(modeler);
      modeler.get('eventBus').fire('elements.changed', {
        elements: _.map(modeler.get('elementRegistry')._elements, object => object.element)
      });
    });
  }, [importedXml]);

  const exportXml = () => {
    let result = null;
    modeler &&
      modeler.saveXML({ format: false }, function(err, xml) {
        if (err) {
          alert('Failed to export BPMN Diagram.');
          return;
        }
        result = xml;
      });
    return result;
  };

  const importXML = e => {
    const input = e.target;
    const reader = new FileReader();
    reader.onload = function() {
      const dataURL = reader.result;
      modeler.importXML(dataURL, error => {
        if (error) {
          alert('Failed to create BPMN Diagram.');
          return null;
        }
        modeler.get('canvas').zoom('fit-viewport');
        setModeler(modeler);
        modeler.get('eventBus').fire('elements.changed', {
          elements: _.map(modeler.get('elementRegistry')._elements, object => object.element)
        });
      });
    };
    reader.readAsText(input.files[0]);
  };

  const exportSvg = () => {
    let result = null;
    modeler &&
      modeler.saveSVG({ format: false }, function(err, xml) {
        if (err) {
          alert('Failed to export BPMN Diagram as SVG.');
          return;
        }
        result = xml;
      });
    return result;
  };

  return (
    <div>
      <Wrapper id="container">
        <Modeler id="modeler" />
        <PropertyPanel id="property-panel" />
        <StyledButtonGroup size="small">
          <Button
            icon="flag checkered"
            color={objectivesAreHidden ? 'red' : 'green'}
            onClick={() => {
              modeler
                .getDefinitions()
                .set(
                  'objectivesAreHidden',
                  _.includes([false, undefined], modeler.getDefinitions().objectivesAreHidden)
                    ? true
                    : false
                );
              setObjectivesAreHidden(modeler.getDefinitions().objectivesAreHidden);
              modeler.get('eventBus').fire('elements.changed', {
                elements: _.map(modeler.get('elementRegistry')._elements, object => object.element)
              });
            }}
          />
          <Button
            icon="image"
            onClick={() =>
              saveAs(
                new Blob([exportSvg()], { type: 'image/svg;charset=utf-8' }),
                `${modeler.getDefinitions().pathwayName || 'pathway'}.svg`
              )
            }
          />
          <Button
            icon="download"
            onClick={() =>
              saveAs(
                new Blob([exportXml()], { type: 'image/svg;charset=utf-8' }),
                `${modeler.getDefinitions().pathwayName || 'pathway'}.bpmn`
              )
            }
          />
          <Button as="label" icon="upload" htmlFor="file" />
          <input id="file" hidden type="file" onChange={e => importXML(e)} />
          <Button icon="cloud download" onClick={() => setTemplateModalOpenness(true)} />
          <Button icon="cloud" onClick={() => setOwnRepositoryModalOpenness(true)} />
          <Button icon="code" onClick={() => setConfiguratorModalOpenness(true)} />
        </StyledButtonGroup>
      </Wrapper>
      <TemplateImporter
        modalIsOpen={templateModalIsOpen}
        closeModal={() => setTemplateModalOpenness(false)}
        setImportedXml={xml => setImportedXml(xml)}
      />
      {ownRepositoryModalIsOpen && (
        <OwnTemplateManager
          modalIsOpen={ownRepositoryModalIsOpen}
          closeModal={() => setOwnRepositoryModalOpenness(false)}
          setImportedXml={xml => setImportedXml(xml)}
          currentXml={exportXml}
          currentSvg={exportSvg}
          modeler={modeler}
        />
      )}
      <Configurator
        modalIsOpen={configuratorModalIsOpen}
        closeModal={() => setConfiguratorModalOpenness(false)}
        setImportedXml={xml => setImportedXml(xml)}
        modeler={modeler}
      />
      {connectionRuleDeclaratorModalProps.isOpen && (
        <ConnectionRuleDeclarator
          activeConnectionElementId={connectionRuleDeclaratorModalProps.activeElementId}
          closeModal={() =>
            setConnectionRuleDeclaratorModalProps({
              isOpen: false,
              activeConnectionElementId: undefined
            })
          }
          modeler={modeler}
        />
      )}
    </div>
  );
}

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
`;

const PropertyPanel = styled.div`
  flex: 0 0 20%;
  border-left: 1px solid grey;
  background-color: #f5f5f5;
  height: 100%;
  overflow: auto;
  div {
    background-color: transparent !important;
  }
`;

const Modeler = styled.div`
  flex: 0 0 80%;
`;

const StyledButtonGroup = styled(Button.Group)`
  position: fixed;
  bottom: 1vw;
  left: 1vw;
  z-index: 1000;
`;

export default BpmnEditor;
