import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import styled from '@emotion/styled';
import _ from 'lodash';
import InlineSvg from 'svg-inline-react';
function BpmnViewer(props) {
  let adress = `${process.env.REACT_APP_dev}` ? `${process.env.REACT_APP_own}` : 'own';
  adress =
    adress.charAt(adress.length - 1) === '/' ? adress.substring(0, adress.length - 1) : adress;
  const [svg, setSvg] = useState();
  useEffect(() => {
    const fetchPath = async () => {
      const path = await Axios.get(`${adress}/pathway?id=${props.match.params.id}`);
      setSvg(path.data.svg);
    };
    fetchPath();
  }, []);
  return svg ? <Wrapper src={svg} /> : null;
}

const Wrapper = styled(InlineSvg)`
  svg {
    height: 100vh;
    width: 100vw;
  }
  position: absolute;
  overflow: scroll;
`;

export default BpmnViewer;
