import entryFactory from 'bpmn-js-properties-panel/lib/factory/EntryFactory';
import _ from 'lodash';
import { is } from 'bpmn-js/lib/util/ModelUtil';
import { QualityIndicatorTypes } from '../../../resources/bpmn4cp';

export default (group, element, customProps) => {
  if (is(element, 'cp:QualityIndicator')) {
    customProps.forEach(customElement => {
      customElement.properties.forEach(property => {
        const displayElement = {
          id: property.id || property.name,
          description: property.description || '',
          label: property.displayName || property.name,
          modelProperty: property.name
        };
        if (_.get(element, 'businessObject.QIType') !== 'Rate-based QI' && property.onlyRateBased)
          return;
        switch (property.type) {
          case 'cp:type':
            group.entries.push(
              entryFactory.selectBox({
                ...displayElement,
                selectOptions: QualityIndicatorTypes.literalValues[0]
              })
            );
            break;
          case 'Boolean':
            group.entries.push(entryFactory.checkbox(displayElement));
            break;
          default:
            group.entries.push(entryFactory.textField(displayElement));
        }
      });
    });
  }
};
