import inherits from 'inherits';
import PaletteProvider from 'bpmn-js/lib/features/palette/PaletteProvider';

export default function CpPalette(
  palette,
  create,
  elementFactory,
  spaceTool,
  lassoTool,
  handTool,
  globalConnect,
  translate
) {
  PaletteProvider.call(
    this,
    palette,
    create,
    elementFactory,
    spaceTool,
    lassoTool,
    handTool,
    globalConnect,
    translate,
    1100
  );

  this.getPaletteEntries = element => {
    function createCpObject(event, type) {
      const shape = elementFactory.createShape({ type });
      create.start(event, shape);
    }

    const cpElementPalette = {};
    cpElementPalette['cp-separator'] = {
      group: 'cp',
      separator: true
    };

    cpElementPalette['create.Objective'] = {
      group: 'cp',
      className: 'fas fa-flag-checkered',
      title: translate('Create Objective'),
      action: {
        dragstart: function(event) {
          createCpObject(event, 'cp:Objective');
        },
        click: function(event) {
          createCpObject(event, 'cp:Objective');
        }
      }
    };

    cpElementPalette['create.QualityIndicator'] = {
      group: 'cp',
      className: 'fas fa-circle',
      title: translate('Create Quality Indicator'),
      action: {
        dragstart: function(event) {
          createCpObject(event, 'cp:QualityIndicator');
        },
        click: function(event) {
          createCpObject(event, 'cp:QualityIndicator');
        }
      }
    };

    return cpElementPalette;
  };
}

inherits(CpPalette, PaletteProvider);

CpPalette.$inject = [
  'palette',
  'create',
  'elementFactory',
  'spaceTool',
  'lassoTool',
  'handTool',
  'globalConnect',
  'translate'
];
