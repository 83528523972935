import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import axios from 'axios';
import { Table, Loader, Header, Button, Modal, Icon, Form } from 'semantic-ui-react';
import styled from '@emotion/styled';

export default ({ modalIsOpen, closeModal, setImportedXml, currentXml, currentSvg, modeler }) => {
  const [allTemplates, setAllTemplates] = useState();

  const [pathwayMetadata, setPathwayMetadata] = useState();

  let adress = process.env.REACT_APP_dev ? `${process.env.REACT_APP_own}` : 'own';
  adress =
    adress.charAt(adress.length - 1) === '/' ? adress.substring(0, adress.length - 1) : adress;
  const deriveTemplates = () =>
    axios.get(`${adress}/pathway`).then(docs => setAllTemplates(docs.data));

  useEffect(() => {
    const definition = modeler.getDefinitions();
    setPathwayMetadata({
      patientName: _.get(definition, 'patientName') || '',
      pathwayName: _.get(definition, 'pathwayName') || ''
    });
    _.isNil(allTemplates) && deriveTemplates();
  }, [allTemplates]);

  useEffect(() => {
    !_.includes(['', undefined], _.get(pathwayMetadata, 'pathwayName')) &&
      modeler.getDefinitions().set('pathwayName', _.get(pathwayMetadata, 'pathwayName'));
    !_.includes(['', undefined], _.get(pathwayMetadata, 'patientName')) &&
      modeler.getDefinitions().set('patientName', _.get(pathwayMetadata, 'patientName'));
  }, [pathwayMetadata]);

  const handleMetadataUpdate = (name, value) => {
    setPathwayMetadata({
      pathwayName: name === 'pathwayName' ? value : pathwayMetadata.pathwayName,
      patientName: name === 'patientName' ? value : pathwayMetadata.patientName
    });
  };
  const TableColumns = [
    {
      name: 'Name',
      content: (row, idx) => <Table.Cell key={`name-${idx}`} children={row.name} />
    },
    {
      name: 'Patient name',
      content: (row, idx) => <Table.Cell key={`name-${idx}`} children={row.patientName} />
    },
    {
      name: 'Import',
      centered: 'true',
      content: (row, idx) => (
        <ClickableCell
          key={`import-${idx}`}
          icon="arrow alternate circle right outline"
          onClick={() => {
            setImportedXml(allTemplates[idx].xml);
            closeModal();
          }}
        />
      )
    },
    {
      name: 'Delete',
      centered: 'true',
      content: (row, idx) => (
        <ClickableCell
          key={`delete-${idx}`}
          icon="trash"
          onClick={() => {
            axios.delete(`${adress}/pathway?id=${row._id.$oid}`).then(() => deriveTemplates());
          }}
        />
      )
    },
    {
      name: 'Save',
      centered: 'true',
      content: (row, idx) => (
        <ClickableCell
          key={`save-${idx}`}
          icon="save"
          onClick={() => {
            axios({
              method: 'put',
              url: `${adress}/pathway`,
              data: { id: row._id.$oid, xml: currentXml(), svg: currentSvg() }
            });
            closeModal();
          }}
        />
      )
    },
    {
      name: 'Viewer-Link',
      centered: 'true',
      content: (row, idx) => (
        <ClickableCell
          key={`copy-${idx}`}
          icon="copy"
          onClick={() =>
            navigator.clipboard.writeText(`${window.location.hostname}/viewer/${row._id.$oid}`)
          }
        />
      )
    }
  ];

  return (
    <Modal
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
      open={modalIsOpen}
      basic
      size="small"
    >
      {_.isNil(allTemplates) ? (
        <Loader />
      ) : (
        <>
          <Header icon="cloud" content="Your template repository" />
          <Modal.Content>
            <Form>
              <Form.Field>
                <Label>Pathway name</Label>
                <input
                  value={_.get(pathwayMetadata, 'pathwayName') || ''}
                  onChange={e => handleMetadataUpdate('pathwayName', e.target.value)}
                />
              </Form.Field>
              <Form.Field>
                <Label>Patient name</Label>
                <input
                  value={_.get(pathwayMetadata, 'patientName') || ''}
                  onChange={e => handleMetadataUpdate('patientName', e.target.value)}
                />
              </Form.Field>
            </Form>
            <Table selectable>
              <Table.Header
                children={
                  <Table.Row
                    children={TableColumns.map((col, idx) => (
                      <Cell key={`header-${idx}`} centered={col.centered} children={col.name} />
                    ))}
                  />
                }
              />
              <Table.Body>
                {_.map(allTemplates, (template, idx) => {
                  const tempTemplate = Object.assign({}, template);
                  const parser = new DOMParser();
                  const xmlDoc = parser.parseFromString(tempTemplate.xml, 'text/xml');
                  const process = xmlDoc.getElementsByTagName('bpmn2:definitions')[0];
                  const name = process
                    ? process.getAttribute('cbpmn:pathwayName') || 'No name given'
                    : 'Corrupt! No ID found!';
                  const patientName = process
                    ? process.getAttribute('cbpmn:patientName') || '-'
                    : '';
                  const row = Object.assign({ name, patientName }, template);
                  return (
                    <Table.Row
                      key={`row-${idx}`}
                      children={TableColumns.map(col => col.content(row, idx))}
                    />
                  );
                })}
                <ClickableRow
                  onClick={() => {
                    axios({
                      method: 'post',
                      url: `${adress}/pathway`,
                      data: { xml: currentXml(), svg: currentSvg() }
                    }).then(() => deriveTemplates());
                    closeModal();
                  }}
                >
                  <Table.Cell
                    children={
                      <>
                        <Icon name="plus" />
                        Save current diagram as new template.
                      </>
                    }
                  />
                  {_.map(_.range(0, TableColumns.length - 1), o => (
                    <Table.Cell key={o} />
                  ))}
                </ClickableRow>
              </Table.Body>
            </Table>
          </Modal.Content>
        </>
      )}
      <Modal.Actions>
        <Button icon="close" content="Cancel" color="red" onClick={closeModal} inverted />
      </Modal.Actions>
    </Modal>
  );
};

const hoverableContent = `cursor: pointer;
:hover {
  i {
    color: lightskyblue !important;
  }
}`;

const ClickableCell = styled(Table.Cell)`
  text-align: center !important;
  ${hoverableContent}
`;

const ClickableRow = styled(Table.Row)`
  ${hoverableContent}
`;

const Cell = styled(Table.Cell)`
  text-align: ${props => (props.centered ? 'center' : 'default')} !important;
`;

const Label = styled.label`
  color: white !important;
`;
