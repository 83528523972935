import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Table, Header, Button, Modal, Checkbox, Segment } from 'semantic-ui-react';

export default ({ activeConnectionElementId, closeModal, modeler }) => {
  const [definedVariables, setDefinedVariables] = useState([]);
  const [intendedVariables, setIntendedVariables] = useState([]);

  useEffect(() => {
    const rootElement = modeler.getDefinitions();

    const tempDefinedVariables = _.isUndefined(rootElement.configuration)
      ? []
      : JSON.parse(rootElement.configuration);

    const activeElement = modeler.get('elementRegistry')._elements[activeConnectionElementId]
      .element;

    let tempIntendedVariables = _.isUndefined(activeElement.businessObject.configuration)
      ? _.map(JSON.parse(JSON.stringify(tempDefinedVariables)), v => _.omit(v, ['value']))
      : JSON.parse(activeElement.businessObject.configuration);

    const intendedVariableIds = _.map(tempIntendedVariables, v => v.id);

    const remainingDefinedVariables = _.filter(
      _.map(JSON.parse(JSON.stringify(tempDefinedVariables)), v => _.omit(v, ['value'])),
      v => !_.includes(intendedVariableIds, v.id)
    );

    tempIntendedVariables = _.concat(tempIntendedVariables, remainingDefinedVariables);
    setIntendedVariables(tempIntendedVariables);
    setDefinedVariables(tempDefinedVariables);
  }, []);

  const TableColumns = [
    {
      name: 'Rule is active',
      id: 'isActive',
      content: (row, idx) => (
        <Table.Cell key={`conf-isActive-${idx}`}>
          <Checkbox
            toggle
            checked={_.keyBy(intendedVariables, 'id')[row.id].ruleIsActive}
            onChange={(e, data) => {
              const mutableVariables = JSON.parse(JSON.stringify(intendedVariables));
              const relevantIndex = _.findIndex(mutableVariables, o => o.id === row.id);
              mutableVariables[relevantIndex].ruleIsActive = data.checked;
              setIntendedVariables(mutableVariables);
            }}
          />
        </Table.Cell>
      )
    },
    {
      name: 'Name',
      id: 'name',
      content: (row, idx) => <Table.Cell key={`var-name-${idx}`} children={row.id} />
    },
    {
      name: 'Intended Value',
      id: 'intendedValue',
      content: (row, idx) => (
        <Table.Cell key={`conf-intendedValue-${idx}`}>
          <Checkbox
            checked={_.keyBy(intendedVariables, 'id')[row.id].value}
            onChange={(e, data) => {
              const mutableVariables = JSON.parse(JSON.stringify(intendedVariables));
              const relevantIndex = _.findIndex(mutableVariables, o => o.id === row.id);
              mutableVariables[relevantIndex].value = data.checked;
              setIntendedVariables(mutableVariables);
            }}
          />
        </Table.Cell>
      )
    }
  ];

  return (
    <Modal closeOnDimmerClick={false} closeOnDocumentClick={false} open={true} basic size="small">
      <Header icon="settings" content="Configure Processflow" />
      <Modal.Content>
        <Segment inverted>
          You can determine here, how variable-shaping should affect the activeness of the subtree
          behind this connection.
        </Segment>
        <Table selectable>
          <Table.Header
            children={
              <Table.Row
                children={TableColumns.map((col, idx) => (
                  <Table.Cell key={`conf-header-${idx}`} children={col.name} />
                ))}
              />
            }
          />
          <Table.Body>
            {_.map(definedVariables, (row, idx) => {
              return (
                <Table.Row
                  key={`row-${idx}`}
                  children={TableColumns.map(col => col.content(row, idx))}
                />
              );
            })}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon="save"
          content="Save"
          color="green"
          onClick={() => {
            modeler
              .get('modeling')
              .updateProperties(
                modeler.get('elementRegistry')._elements[activeConnectionElementId].element,
                {
                  configuration: JSON.stringify(intendedVariables)
                }
              );
            closeModal();
          }}
          inverted
        />
        <Button icon="close" content="Cancel" color="red" onClick={closeModal} inverted />
      </Modal.Actions>
    </Modal>
  );
};
