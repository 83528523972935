import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Table, Header, Button, Modal, Icon, Input, Checkbox } from 'semantic-ui-react';
import styled from '@emotion/styled';

export default ({ modalIsOpen, closeModal, modeler }) => {
  const [variables, setVariables] = useState([]);
  const [currentVariableName, setCurrentVariableName] = useState('');

  useEffect(() => {
    if (modeler !== null) {
      const definition = modeler.getDefinitions();
      setVariables(
        _.isUndefined(definition.configuration) ? [] : JSON.parse(definition.configuration)
      );
    }
  }, [modeler, modalIsOpen]);

  const TableColumns = [
    {
      name: 'Name',
      id: 'name',
      content: (row, idx) => <Table.Cell key={`var-name-${idx}`} children={row.id} />
    },
    {
      name: 'Value',
      id: 'value',
      content: (row, idx) => (
        <Table.Cell key={`var-value-${idx}`}>
          <Checkbox
            checked={variables[idx].value}
            indeterminate={_.isUndefined(variables[idx].value)}
            onChange={(e, data) => {
              const mutableVariables = [...variables];
              mutableVariables[idx].value = data.checked;
              setVariables(mutableVariables);
            }}
          />
        </Table.Cell>
      )
    },
    {
      name: '',
      id: 'delete-configurator',
      content: (row, idx) => (
        <ClickableCell
          key={`var-delete-${idx}`}
          icon="trash"
          onClick={() => {
            let mutableVariables = [...variables];
            mutableVariables = _.filter(mutableVariables, element => element.id !== row.id);
            setVariables(mutableVariables);
          }}
        />
      )
    }
  ];

  const addVariable = () => {
    if (_.map(variables, el => el.id).includes(currentVariableName) || currentVariableName === '')
      return;
    setVariables(_.concat(variables, { id: currentVariableName, type: 'Boolean' }));
    setCurrentVariableName('');
  };

  return (
    <Modal
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
      open={modalIsOpen}
      basic
      size="small"
    >
      <Header icon="code" content="Variables and configuration" />
      <Modal.Content>
        <Table selectable>
          <Table.Header
            children={
              <Table.Row
                children={TableColumns.map((col, idx) => (
                  <Table.Cell key={`var-header-${idx}`} children={col.name} />
                ))}
              />
            }
          />
          <Table.Body>
            {_.map(variables, (row, idx) => {
              return (
                <Table.Row
                  key={`row-${idx}`}
                  children={TableColumns.map(col => col.content(row, idx))}
                />
              );
            })}
            <Table.Row>
              <Table.Cell>
                <Input
                  value={currentVariableName}
                  onChange={(e, target) => setCurrentVariableName(target.value)}
                  onKeyDown={e => e.keyCode === 13 && addVariable()}
                />
              </Table.Cell>
              <Table.Cell>
                <Checkbox indeterminate disabled />
              </Table.Cell>
              <ClickableCell onClick={() => addVariable()}>
                <Icon name="plus" />
              </ClickableCell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon="save"
          content="Save"
          color="green"
          onClick={() => {
            modeler.getDefinitions().set('configuration', JSON.stringify(variables));
            modeler.get('eventBus').fire('elements.changed', {
              elements: _.map(modeler.get('elementRegistry')._elements, object => object.element)
            });
            closeModal();
          }}
          inverted
        />
        <Button icon="close" content="Cancel" color="red" onClick={closeModal} inverted />
      </Modal.Actions>
    </Modal>
  );
};

const hoverableContent = `cursor: pointer;
:hover {
  i {
    color: lightskyblue !important;
  }
}`;

const ClickableCell = styled(Table.Cell)`
  text-align: center !important;
  ${hoverableContent}
`;
