export const customCbpmnProps = [
  {
    name: 'ConfigurableNode',
    extends: ['bpmn:Gateway', 'bpmn:Task', 'bpmn:SubProcess'],
    properties: [
      {
        id: 'configurableToggler',
        name: 'configurable',
        displayName: 'Configurable',
        description: '',
        isAttr: true,
        type: 'Boolean'
      }
    ]
  }
];

export const hiddenCustomCbpmnProps = [
  {
    name: 'Metadata',
    extends: ['bpmn:Definitions'],
    properties: [
      ...['configuration', 'pathwayName', 'patientName'].map(id => ({
        id,
        name: id,
        isAttr: true,
        type: 'String'
      })),
      {
        id: 'objectivesAreHidden',
        name: 'objectivesAreHidden',
        isAttr: true,
        type: 'Boolean'
      }
    ]
  },
  {
    name: 'BlockingEdge',
    extends: ['bpmn:SequenceFlow'],
    properties: [
      {
        id: 'configuration',
        name: 'configuration',
        isAttr: true,
        type: 'String'
      }
    ]
  }
];

const cbpmn = {
  name: 'C-BPMN',
  prefix: 'cbpmn',
  uri: 'http://cbpmn',
  xml: {
    tagAlias: 'lowerCase'
  },
  associations: [],
  types: [...customCbpmnProps, ...hiddenCustomCbpmnProps]
};

export default cbpmn;
