import inherits from 'inherits';
import { attr, appendTo, create, append, innerSVG } from 'tiny-svg';

import BpmnRenderer from 'bpmn-js/lib/draw/BpmnRenderer';
import { isStartElementReachable, isNotBlocking } from '../../helpers/isStartElementReachable';
import _ from 'lodash';
import { CpTasks, configurableCpElements } from '../../resources/bpmn4cp';
import { is } from 'bpmn-js/lib/util/ModelUtil';
import icons from '../../resources/icons';

import { createLine } from 'diagram-js/lib/util/RenderUtil';
import { isAny } from 'bpmn-js/lib/features/modeling/util/ModelingUtil';

export default function ConfigurableRenderer(
  config,
  eventBus,
  styles,
  pathMap,
  canvas,
  textRenderer,
  elementRegistry,
  bpmnjs
) {
  BpmnRenderer.call(this, config, eventBus, styles, pathMap, canvas, textRenderer, 2100);

  const parentShapeCanReachStartElement = element => {
    return isStartElementReachable(_.get(element, 'parent.id'), elementRegistry, bpmnjs);
  };

  this.canRender = function(element) {
    if (_.get(bpmnjs, '_definitions.objectivesAreHidden') && is(element, 'cp:Objective'))
      return true;
    return !element.labelTarget;
  };

  this.drawShape = function(parent, element) {
    let bpmnShape = null;
    switch (true) {
      case _.includes(CpTasks.map(task => is(element, `cp:${task.name}`)), true):
        element.type = 'bpmn:Task';
        bpmnShape = this.drawBpmnShape(parent, element);
        CpTasks.forEach(task => {
          if (is(element, `cp:${task.name}`)) {
            const icon = create('path');
            attr(icon, { d: icons[task.icon][4], transform: 'translate(4.5,4.5) scale(0.03)' });
            appendTo(icon, parent);
            element.type = `cp:${task.name}`;
          }
        });
        break;
      case is(element, `cp:Objective`):
        const isHidden = _.get(bpmnjs, '_definitions.objectivesAreHidden');
        element.height = isHidden ? 0 : 36;
        element.width = isHidden ? 0 : 36;
        if (isHidden) return null;
        element.offset = 0;
        const objectiveSvg = create('path');
        attr(objectiveSvg, {
          d: icons['flag-checkered'][4],
          transform: 'translate(-4, -4) scale(0.08)'
        });
        append(parent, objectiveSvg);
        break;
      case is(element, `cp:QualityIndicator`):
        element.height = 36;
        element.width = 36;
        element.offset = 0;
        const qualityIndicatorSvg = create('path');
        attr(qualityIndicatorSvg, {
          d: icons['circle'][4],
          transform: 'translate(-2.5, -2.5) scale(0.08)',
          stroke: 'black',
          fill: 'green',
          strokeWidth: '30'
        });
        append(parent, qualityIndicatorSvg);
        break;
      case is(element, 'cp:IntermediateThrowMilestoneEvent'):
        element.type = 'bpmn:IntermediateThrowEvent';
        bpmnShape = this.drawBpmnShape(parent, element);
        const icon = create('path');
        attr(icon, { d: icons['map-marker-alt'][4], transform: 'translate(10,8) scale(0.04)' });
        appendTo(icon, parent);
        break;
      default:
        bpmnShape = this.drawBpmnShape(parent, element);
    }

    //append evidence indicator to applicable elements
    if (
      _.includes(
        configurableCpElements.map(cElement => is(element, `bpmn:${cElement.name}`)),
        true
      ) &&
      !_.isUndefined(_.get(element, 'businessObject.evidenceIndicator'))
    ) {
      const indicator = create('path');
      const isTask = is(element, `bpmn:Task`);
      attr(indicator, {
        d:
          'M5.127,1.151l-0.442,0.994l0,3.203l-0.774,0l-0.664,0.553l-0.553,0l-0.553,0.773l-0.442,0l-0.443,1.325l0,1.325l1.438,1.547l0,0.994l0.995,0.331l0.885,0l1.106,-0.331l0,-1.547l1.328,-3.092l-0.885,-1.546l0,1.767l0,-5.302l-0.443,-0.994l-0.553,0l0,0Z',
        fill: '#f90',
        stroke: 'black',
        strokeWidth: '.5px'
      });
      const text = create('text');
      innerSVG(text, _.get(element, 'businessObject.evidenceIndicator'));
      attr(text, { fontSize: '3.5px', transform: 'translate(3,9.5)' });
      const group = create('g');
      attr(group, { transform: `translate(${isTask ? '85,-25' : '40,-5'}) scale(3.5)` });
      append(group, indicator);
      append(group, text);
      append(parent, group);
    }

    // display specializable elements blue dotted if relevant 'children' are empty
    isAny(element, ['bpmn:Task', 'bpmn:SubProcess']) &&
      element.businessObject.configurable &&
      (is(element, 'bpmn:Task')
        ? _.isNil(_.get(element, 'businessObject.name'))
        : _.get(element, 'children.length') === 0) &&
      attr(bpmnShape, { strokeDasharray: '5 5', strokeWidth: '3px', stroke: '#2185d0' });

    // reduce opacity if element is hidden by configuration
    is(element, 'bpmn:Gateway') &&
      element.businessObject.configurable &&
      attr(bpmnShape, { strokeWidth: '5px' });

    isStartElementReachable(element.id, elementRegistry, bpmnjs) &&
    parentShapeCanReachStartElement(element)
      ? attr(parent, { opacity: 1 })
      : attr(parent, { opacity: 0.2 });
    return bpmnShape;
  };

  this.drawConnection = function(parent, element) {
    let bpmnConnection = null;
    switch (true) {
      case is(element, `cp:ObjectiveAssociation`) || is(element, `cp:QualityIndicatorAssociation`):
        break;
      default:
        bpmnConnection = this.drawBpmnConnection(parent, element);
    }

    // create custom Connections
    if (['cp:ObjectiveAssociation', 'cp:QualityIndicatorAssociation'].includes(element.type)) {
      const isHiddenObjective =
        _.get(bpmnjs, '_definitions.objectivesAreHidden') &&
        element.type === 'cp:ObjectiveAssociation';
      var attrs = isHiddenObjective
        ? null
        : styles.computeStyle(attrs, {
            stroke: 'black',
            strokeWidth: 2,
            strokeDasharray: '2 2',
            fill: 'transparent'
          });
      bpmnConnection = append(parent, createLine(element.waypoints, attrs));
    }

    const sourceElementId = _.get(
      elementRegistry,
      `_elements[${_.get(element, 'businessObject.sourceRef.id')}].element.id`
    );
    isNotBlocking(element, bpmnjs) &&
    isStartElementReachable(sourceElementId, elementRegistry, bpmnjs) &&
    parentShapeCanReachStartElement(element)
      ? attr(parent, { opacity: 1 })
      : attr(parent, { opacity: 0.2 });

    return bpmnConnection;
  };
}

inherits(ConfigurableRenderer, BpmnRenderer);

ConfigurableRenderer.prototype.drawBpmnShape = BpmnRenderer.prototype.drawShape;
ConfigurableRenderer.prototype.drawBpmnConnection = BpmnRenderer.prototype.drawConnection;

ConfigurableRenderer.$inject = [
  'config.bpmnRenderer',
  'eventBus',
  'styles',
  'pathMap',
  'canvas',
  'textRenderer',
  'elementRegistry',
  'bpmnjs',
  'styles'
];
