import inherits from 'inherits';

import RuleProvider from 'diagram-js/lib/features/rules/RuleProvider';
import { find } from 'min-dash';
import { is, getBusinessObject } from 'bpmn-js/lib/util/ModelUtil';
import { isAny } from 'bpmn-js/lib/features/modeling/util/ModelingUtil';

import _ from 'lodash';

export default function CpRules(eventBus) {
  RuleProvider.call(this, eventBus);
}

inherits(CpRules, RuleProvider);

CpRules.$inject = ['eventBus'];

const isCpElement = shape => isAny(shape, ['cp:Objective', 'cp:QualityIndicator']);

CpRules.prototype.canConnectAssociation = function() {
  return true;
};

CpRules.prototype.init = function() {
  this.addRule('shape.replace', context => {
    const cEl = context.element;
    if (isCpElement(cEl) && !is(cEl, 'bpmn:Activity') && !is(cEl, 'bpmn:Event')) return false;
  });

  this.addRule('elements.move', 2000, function(context) {
    return true;
  });

  this.addRule('connection.start', 2000, context =>
    isCpElement(context.source) || isCpElement(context.target) ? true : undefined
  );

  this.addRule('connection.create', 2000, context => canConnect(context.source, context.target));

  this.addRule('connection.reconnectStart', 2000, context =>
    canConnect(context.hover || context.source, context.connection.target)
  );

  this.addRule('connection.reconnectEnd', 2000, context =>
    canConnect(context.connection.source, context.hover || context.target)
  );
};

function canConnect(source, target) {
  if (
    (is(source, 'cp:Objective') && is(target, 'bpmn:Task')) ||
    (is(target, 'cp:Objective') && is(source, 'bpmn:Task'))
  )
    return { type: 'cp:ObjectiveAssociation' };

  if (
    (is(source, 'cp:QualityIndicator') && is(target, 'bpmn:Task')) ||
    (is(target, 'cp:QualityIndicator') && is(source, 'bpmn:Task'))
  )
    return { type: 'cp:QualityIndicatorAssociation' };

  if (is(source, 'bpmn:DataObjectReference') && is(target, 'cp:QualityIndicator'))
    return { type: 'bpmn:Association' };

  if (
    (is(source, 'cp:Objective') && is(target, 'cp:QualityIndicator')) ||
    (is(target, 'cp:Objective') && is(source, 'cp:QualityIndicator'))
  )
    return { type: 'cp:ObjectiveAssociation' };
}
