import inherits from 'inherits';

import PropertiesActivator from 'bpmn-js-properties-panel/lib/PropertiesActivator';
import eventProps from 'bpmn-js-properties-panel/lib/provider/bpmn/parts/EventProps';
import linkProps from 'bpmn-js-properties-panel/lib/provider/bpmn/parts/LinkProps';
import documentationProps from 'bpmn-js-properties-panel/lib/provider/bpmn/parts/DocumentationProps';

import { customCbpmnProps } from '../../resources/cbpmn';
import { configurableCpElements, QualityIndicator } from '../../resources/bpmn4cp';

import automaticPropPusher from './parts/automaticPropPusher';
import qiPropPusher from './parts/qiPropPusher';

function createGeneralTabGroups(element, bpmnFactory, elementRegistry, translate) {
  var detailsGroup = {
    id: 'details',
    label: 'Details',
    entries: []
  };
  linkProps(detailsGroup, element, translate);
  eventProps(detailsGroup, element, bpmnFactory, elementRegistry, translate);

  var documentationGroup = {
    id: 'documentation',
    label: 'Documentation',
    entries: []
  };

  var cbpmnGroup = {
    id: 'cbpmn',
    label: 'Configurable Elements',
    entries: []
  };
  automaticPropPusher(cbpmnGroup, element, customCbpmnProps);

  var cpGroup = {
    id: 'cp',
    label: 'Clinical Pathway Options',
    entries: []
  };
  automaticPropPusher(cpGroup, element, configurableCpElements);

  var qiGroup = {
    id: 'qi',
    label: 'Quality Indicator Options',
    entries: []
  };
  qiPropPusher(qiGroup, element, QualityIndicator);

  documentationProps(documentationGroup, element, bpmnFactory, translate);

  return [detailsGroup, cpGroup, qiGroup, cbpmnGroup, documentationGroup];
}

export default function CbpmnPropertiesProvider(eventBus, bpmnFactory, elementRegistry, translate) {
  PropertiesActivator.call(this, eventBus);

  this.getTabs = element => [
    {
      id: 'general',
      label: 'General',
      groups: createGeneralTabGroups(element, bpmnFactory, elementRegistry, translate)
    }
  ];
}

inherits(CbpmnPropertiesProvider, PropertiesActivator);

CbpmnPropertiesProvider.$inject = ['eventBus', 'bpmnFactory', 'elementRegistry', 'translate'];
