import configurableRenderer from './configurableRenderer';
import cpReplaceMenu from './cpReplaceMenu';
import cpPalette from './cpPalette';
import propertiesPanelModule from 'bpmn-js-properties-panel';
import configurablePropertiesActivator from './configurablePropertiesActivator';
import CliModule from 'bpmn-js-cli';
import CpRules from './cpRules';

const modules = [
  CliModule,
  propertiesPanelModule,
  configurableRenderer,
  cpReplaceMenu,
  cpPalette,
  configurablePropertiesActivator,
  CpRules
];

export default modules;
