import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import axios from 'axios';
import { Table, Loader, Header, Button, Modal } from 'semantic-ui-react';

export default ({ modalIsOpen, closeModal, setImportedXml }) => {
  const [allTemplates, setAllTemplates] = useState();
  const [selectedTemplateId, setSelectedTemplateId] = useState();

  let adress = process.env.REACT_APP_dev ? `${process.env.REACT_APP_dev}` : 'dev';
  adress =
    adress.charAt(adress.length - 1) === '/' ? adress.substring(0, adress.length - 1) : adress;

  useEffect(() => {
    _.isNil(allTemplates) &&
      axios.get(`${adress}/pathway`).then(docs => setAllTemplates(docs.data));
  });

  return (
    <Modal
      closeOnDimmerClick={false}
      closeOnDocumentClick={false}
      open={modalIsOpen}
      basic
      size="small"
    >
      {_.isNil(allTemplates) ? (
        <Loader />
      ) : allTemplates.length === 0 ? (
        <>
          <Header icon="search" content="No pathways found" />
          <Modal.Content>Sorry, no pathways were found in the repository.</Modal.Content>
        </>
      ) : (
        <>
          <Header icon="cloud download" content="Select a template to import" />
          <Modal.Content>
            <Table selectable>
              <Table.Body>
                {_.map(allTemplates, (template, idx) => {
                  const tempTemplate = Object.assign({}, template);
                  const parser = new DOMParser();
                  const xmlDoc = parser.parseFromString(tempTemplate.xml, 'text/xml');
                  const process = xmlDoc.getElementsByTagName('bpmn2:definitions')[0];
                  const name = process
                    ? process.getAttribute('cbpmn:pathwayName') || 'No name given'
                    : 'Corrupt! No ID found!';
                  return (
                    <Table.Row active={selectedTemplateId === idx} key={idx}>
                      <Table.Cell children={name} onClick={() => setSelectedTemplateId(idx)} />
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Modal.Content>
        </>
      )}
      <Modal.Actions>
        {!_.isNil(selectedTemplateId) && (
          <Button
            icon="checkmark"
            content="Import"
            color="green"
            onClick={() => {
              setImportedXml(allTemplates[selectedTemplateId].xml);
              closeModal();
            }}
            inverted
          />
        )}
        <Button icon="close" content="Cancel" color="red" onClick={closeModal} inverted />
      </Modal.Actions>
    </Modal>
  );
};
