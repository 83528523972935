import _ from 'lodash';

export const CpTasks = [
  { name: 'TherapyTask', displayName: 'Therapy Task', icon: 'syringe' },
  { name: 'DiagnosisTask', displayName: 'Diagnosis Task', icon: 'stethoscope' },
  { name: 'SupportingTask', displayName: 'Supporting Task', icon: 'hand-paper' },
  { name: 'EducationTask', displayName: 'Education Task', icon: 'book-open' },
  { name: 'HomeVisitTask', displayName: 'Home Visit Task', icon: 'home' },
  { name: 'PhoneContactTask', displayName: 'Phone Contact Task', icon: 'phone' },
  { name: 'MonitoringTask', displayName: 'Monitoring Task', icon: 'video' }
].map(({ name, displayName, icon }) => ({ name, icon, displayName, superClass: ['bpmn:Task'] }));

export const EvidenceLevel = {
  name: 'EvidenceLevel',
  literalValues: [
    ['', 'A', 'B', 'C', 'D', 'E', 'F'].map(value => ({
      name: value,
      value
    }))
  ]
};

export const QualityIndicatorTypes = {
  name: 'QITypeType',
  literalValues: [
    ['Sentinel QI', 'Rate-based QI'].map(value => ({
      name: value,
      value
    }))
  ]
};

export const QualityIndicator = ['QualityIndicator'].map(name => ({
  name,
  superClass: ['Indicator'],
  properties: [
    {
      id: 'QIType',
      name: 'QIType',
      isAttr: true,
      displayName: 'QI Type',
      type: 'type'
    },
    ...[
      { id: 'QIName', displayName: 'QI Name' },
      { id: 'QIDomain', displayName: 'QI Domain' },
      { id: 'measureFrequency', displayName: 'Measure Frequency' },
      { id: 'InterestGroup', displayName: 'Interest Group' },
      { id: 'QIWeighting', displayName: 'QI Weighting' },
      { id: 'QIMeasure', displayName: 'QI Measure' },
      { id: 'QIValidity', displayName: 'Valid until' },
      { id: 'QIReasoning', displayName: 'QI Reasoning' },
      { id: 'calculationDescription', displayName: 'Calculation Description', onlyRateBased: true },
      { id: 'denominatorMin', displayName: 'Denominator Min', onlyRateBased: true },
      { id: 'numerator', displayName: 'Numerator', onlyRateBased: true },
      { id: 'denominatorDescription', displayName: 'Denominator Description', onlyRateBased: true },
      {
        id: 'referenceRangedenominator',
        displayName: 'Reference Range Denominator',
        onlyRateBased: true
      },
      { id: 'numeratorMin', displayName: 'Numerator Min', onlyRateBased: true },
      { id: 'numeratorDescription', displayName: 'Numerator Description', onlyRateBased: true }
    ].map(({ id, displayName, onlyRateBased }) => ({
      id,
      name: id,
      isAttr: true,
      displayName,
      type: 'String',
      onlyRateBased
    }))
  ]
}));

export const configurableCpElements = [
  ...['Task', 'Gateway'].map(name => ({
    name,
    extends: [`bpmn:${name}`],
    properties: [
      {
        id: 'evidenceIndicator',
        name: 'evidenceIndicator',
        isAttr: true,
        displayName: 'Evidence Indicator',
        type: 'EvidenceIndicator'
      },
      ...[
        { id: 'cpgName', displayName: 'CPG-Name' },
        { id: 'url', displayName: 'URL' },
        { id: 'paragraphFrom', displayName: 'From paragraph...' },
        { id: 'paragraphTo', displayName: '...to paragraph' }
      ].map(({ id, displayName }) => ({
        id,
        name: id,
        isAttr: true,
        displayName,
        type: 'String'
      })),
      ...[
        { id: 'pageFrom', displayName: 'From page...' },
        { id: 'pageTo', displayName: '...to page' }
      ].map(({ id, displayName }) => ({
        id,
        name: id,
        isAttr: true,
        displayName,
        type: 'Integer'
      }))
    ]
  }))
];

export default {
  name: 'Clinical Pathways',
  uri: 'http://some-company/schema/bpmn/cp',
  prefix: 'cp',
  xml: {
    tagAlias: 'lowerCase'
  },
  types: [
    ...CpTasks,
    ...configurableCpElements,
    {
      name: 'SimultanParallelGateway',
      superClass: ['bpmn:ParallelGateway']
    },
    {
      name: 'EvidenceBasedGateway',
      superClass: ['bpmn:ComplexGateway']
    },
    {
      name: 'Indicator',
      isAbstract: true,
      superClass: ['bpmn:DataObjectReference']
    },
    ...['Objective'].map(name => ({ name, superClass: ['Indicator'] })),
    ...QualityIndicator,
    ...['ObjectiveAssociation', 'QualityIndicatorAssociation'].map(name => ({
      name,
      superClass: ['bpmn:Association']
    })),
    {
      name: 'IntermediateThrowMilestoneEvent',
      superClass: ['bpmn:IntermediateThrowEvent']
    }
  ]
};
