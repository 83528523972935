import _ from 'lodash';
import { is } from 'bpmn-js/lib/util/ModelUtil';
import { isAny } from 'bpmn-js/lib/features/modeling/util/ModelingUtil';

export const isStartElementReachable = (initId, elementRegistry, bpmnjs) => {
  let visitedShapeIds = [],
    startElementIsReachable = false;

  const iterator = id => {
    const currentElement = _.get(elementRegistry, `_elements[${id}].element`);
    if (
      isAny(currentElement, [
        'bpmn:DataStoreReference',
        'bpmn:DataObjectReference',
        'bpmn:Group',
        'cp:QualityIndicator',
        'cp:Objective'
      ])
    )
      startElementIsReachable = true;
    if (
      _.isUndefined(_.get(currentElement, 'incoming[0]')) ||
      is(currentElement, 'bpmn:Participant')
    ) {
      startElementIsReachable = true;
      return;
    }
    const idsToCheck = currentElement.incoming
      .filter(inc => {
        const isSequenceFlow = is(inc, 'bpmn:SequenceFlow');
        const isNotVisited = !_.includes(visitedShapeIds, inc.source.id);
        const elementIsNotBlocking = isNotBlocking(inc, bpmnjs);
        return isNotVisited && elementIsNotBlocking && isSequenceFlow;
      })
      .map(filteredConnections => filteredConnections.source.id);
    visitedShapeIds = visitedShapeIds.concat(id);
    idsToCheck.forEach(id => iterator(id));
  };

  iterator(initId);
  return startElementIsReachable;
};

export const isNotBlocking = (inc, bpmnjs) => {
  if (!_.get(inc, 'source.businessObject.configurable')) return true;
  let isNotBlocking = true;
  const actualValues = _.get(bpmnjs, '_definitions.configuration')
    ? _.keyBy(JSON.parse(_.get(bpmnjs, '_definitions.configuration')), 'id')
    : {};
  const intendedValues = inc.businessObject.configuration
    ? _.filter(JSON.parse(inc.businessObject.configuration), v => v.ruleIsActive)
    : [];
  _.forEach(intendedValues, rule => {
    const value = _.get(actualValues, `[${rule.id}].value`);
    if (
      value === undefined ||
      value === rule.value ||
      (value === false && rule.value === undefined)
    )
      return;
    isNotBlocking = false;
  });
  return isNotBlocking;
};
